<template>
    <div class="blog-post-index-page">
        <main class="main-post">
            <h1 v-if="storedArticle.data.article.title" class="main-post__title">
                {{ titleH1 }}
            </h1>
            <div v-if="storedArticle.data.article.image" class="main-post__image">
                <img
                    :src="storedArticle.data.article.image.url"
                    :alt="storedArticle.data.article.title"
                    :title="storedArticle.data.article.title"
                />
            </div>

            <div
                id="post"
                class="main-post__content"
                v-html="removeXSS(storedArticle.data.article.content)"
            ></div>

            <hr class="mb-[30xp] bg-gray-600" />
            <section v-if="storedArticle.data.article.author">
                <article class="about-author">
                    <div class="about-author__head">
                        <picture class="head-picture">
                            <img
                                class="head-picture__img"
                                :src="storedArticle.data.article.author.image.url"
                                :alt="storedArticle.data.article.author.name"
                                :title="storedArticle.data.article.author.name"
                            />
                        </picture>

                        <div class="head-info">
                            <h3 class="head-info__title">Acerca del autor</h3>
                            <NuxtLink
                                :to="`/autores/${storedArticle.data.article.author.nickname}`"
                                class="head-info__name"
                                >{{ storedArticle.data.article.author.name }}</NuxtLink
                            >
                            <br />
                            <span>{{ storedArticle.data.article.author.position }}</span>
                        </div>
                    </div>
                    <div
                        class="about-author__description"
                        v-html="storedArticle.data.article.author.description"
                    ></div>
                </article>
            </section>
        </main>
    </div>
</template>

<script lang="ts" setup>
import { removeXSS, replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

const Route = useRoute()

const { buildHeaders, baseUrl, endpoints } = useApiClient()

const { data: responseData, error } = await useAsyncData('blog-article-data', async () => {
    const entry = await $fetch<Api.Responses.Pages.Articles | Api.Responses.ResourceNotFound>(
        (endpoints.pages.articles.path + '/' + Route.params.article) as string,
        {
            headers: buildHeaders(),
            method: 'GET',
            baseURL: baseUrl,
        },
    )

    return entry
})

if (error.value || !responseData.value || responseData.value?.feedback !== 'data_success') {
    throw createError({
        statusCode: 404,
        message: 'Page not found',
    })
}

if (responseData.value?.data?.article?.category?.slug !== Route.params.category) {
    throw createError({
        statusCode: 410,
        message: 'Page deleted (gone)',
    })
}

const storedArticle = ref(responseData.value)

const { currentDate } = useDateFunctions()

const currentDates = currentDate()

const {
    public: {
        variant: {
            metaData: { siteName, siteOrigin },
        },
    },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':year', String(currentDates.year) || ''],
        [':title', storedArticle.value.data.article.title || ''],
        [
            ':description',
            storedArticle.value.data.article.content?.replace(
                /<\/?[a-z][a-z0-9]*[^<>]*>|<!--.*?-->/gim,
                '',
            ) || '',
        ],
        [':site', siteName || ''],
    ],
    [
        storedArticle.value.data.article.seo?.title || '',
        storedArticle.value.data.article.seo?.description || '',
    ],
)

const [titleH1] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':year', String(currentDates.year) || ''],
    ],
    [storedArticle.value.data.article.title || ''],
)

useSeoMeta({
    title: title,
    ogUrl: siteOrigin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    ogImage: storedArticle.value.data.article.image?.url || '',
    twitterImage: storedArticle.value.data.article.image?.url || '',
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.blog-post-index-page {
    @apply container mb-10 mt-5 max-w-[860px];

    .main-post {
        @apply flex w-full flex-col gap-y-[30px];
        &__title {
            @apply my-3;
        }
        &__image {
            @apply h-48 w-full sm:h-60 md:h-80 lg:h-96;
            img {
                @apply h-full w-full object-cover;
            }
        }
        &__content {
            @apply prose prose-gray max-w-none px-1 !text-black transition duration-300 lg:prose-xl prose-headings:font-normal prose-h2:text-lg prose-h2:text-site-primary prose-table:w-full prose-table:border-collapse prose-table:rounded prose-table:border prose-table:border-gray-400 prose-table:text-left prose-th:h-12 prose-th:border-l prose-th:border-gray-400 prose-th:bg-gray-300 prose-th:stroke-gray-700 prose-th:px-6 prose-th:text-sm prose-th:font-medium prose-th:text-gray-900 first:prose-th:rounded-tl first:prose-th:border-l-0 last:prose-th:rounded-br prose-td:h-12 prose-td:border-l prose-td:border-t prose-td:border-gray-400 prose-td:stroke-gray-500 prose-td:px-6 prose-td:text-sm prose-td:text-gray-700 first:prose-td:border-l-0 prose-img:mx-auto prose-table:sm:border-separate prose-table:sm:border-spacing-0 lg:py-2 lg:prose-h2:text-[28px];
        }

        &__content :where(p):first-child {
            @apply mt-0;
        }

        & .about-author {
            @apply space-y-5;

            &__head {
                @apply flex items-center gap-x-5;

                & .head-picture {
                    @apply block size-[68px];

                    &__img {
                        @apply block size-full rounded-full object-contain;
                    }
                }

                & .head-info {
                    &__title {
                        @apply mb-3 text-base font-medium uppercase text-site-primary md:mb-5;
                    }
                    &__name {
                        @apply text-2xl font-semibold text-site-primary;
                    }
                }
            }

            &__description {
                @apply md:ml-[90px] lg:text-xl;
            }
        }
    }
}
</style>
